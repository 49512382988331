import Vue from 'vue'

import { eagerRoutes } from './routes'
import store from './store'

import installCMSComponents from '@simpl/cms-components'

import { MUTATIONS } from './store/consts'
import { loadLocalizations } from '@simpl/core/utils/load-localizations'
import { addRoutes } from '@simpl/core/plugins/router'

export default async function register (_Vue: typeof Vue, app: Vue) {
  await installCMSComponents(_Vue)

  await loadLocalizations(
    app,
    () => require.context('./locales', true, /[a-z0-9-_]+\.(json|ts)$/i)
  )

  addRoutes(eagerRoutes)

  if (!app.$store.state.cms) {
    await app.$store.registerModule('cms', store)
  }

  if (app.$store.state.cms.dark === null) {
    app.$store.commit(`cms/${MUTATIONS.SET_THEME}`, app.$vuetify.theme.dark)
  }

  return () => {
    if (app.$store.state.cms) {
      app.$store.unregisterModule('cms')
    }
  }
}
